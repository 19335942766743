import React, { useState, useEffect, useCallback } from 'react';
import Room from './Room';

const params = new URL(window.location.href).pathname.substring(1).split('/')

const VideoChat = () => {
  const [token, setToken] = useState('');

  useEffect(() => {
    const queryParam = new URLSearchParams(window.location.search)
    const sessionId = queryParam.get('sessionId');

    fetch(`${process.env.REACT_APP_BASEURL}get/sessionById`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ sessionId })
    })
      .then(res => res.json())
      .then(data => {
        console.log('Condition ', Boolean(data && data.auth_token))
        console.log('Token ', data.auth_token)
        if (data && data.auth_token) {
          twilioAPICall(data.auth_token)
        }
      })

    function twilioAPICall(authToken) {
      fetch(`${process.env.REACT_APP_BASEURL}twilioToken/${params[0]}/${params[1]}`, {
        method: 'GET',
        headers: {
          Authorization: authToken,
          'Content-Type': 'application/json'
        }
      })
        .then(res => res.json())
        .then(data => {
          setToken(data.token);
        })
    }
  }, []);

  const handleLogout = useCallback(event => {
    setToken(null);
  }, []);

  return (
    token ? <Room roomName={params[1]} token={token} handleLogout={handleLogout} /> : <h1>You are disconnected</h1>
  );
};

export default VideoChat;